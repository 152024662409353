
import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './data-table.css'
import { useHistory } from 'react-router-dom';
import { Dialog } from "primereact/dialog";
import Button from '../Button/custom-button';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSurveyDefinition, getSurveyDefinition } from '../../Home/Survey/survey-store/survey-actions';
import { loadToaster } from '../toaster/toaster-actions-creators';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from "primereact/api";

const DataTableDemo = ({ data }) => {
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const [showDialog, setShowDialog] = useState(false);
    const { surveydefinition } = useSelector((state) => state.surveyDetails);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        survey_description: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    });
    useEffect(() => {
        // if (data && data.length)
        setProducts(data)
    }, [data]);


    const handleSettingsClick = (rowData) => {
        console.log('Selected row data:', rowData);
        dispatch(fetchSurveyDefinition(rowData.survey_id));

        setShowDialog(true);

    }

    const actionBodyTemplate = (rowData) => {
        return (
            <Button type="button" icon="pi pi-download"
                className="p-button-sm"
                style={{ width: '2rem', fontSize: 'small', height: '2rem' }}
                onClick={() => handleSettingsClick(rowData)} />
        );
    }
    const handleRowDoubleClick = (event) => {
        setSelectedProduct(event.data);
        console.log(event.data);
        history.push(`/home/survey/edit-survey/${event.data.survey_id}`, { data: event.data });


    };

    const hideDialogBox = () => {
        setShowDialog(false);
    }
    const onDownloadClick = () => {
        // Get the text to download
        const text = surveydefinition;

        // Convert the string to an object
        const obj = JSON.parse(text);

        // Convert the object back to a string in the desired format
        const formattedText = JSON.stringify(obj, null, 2);

        // Create a new Blob object with the text content
        const blob = new Blob([formattedText], { type: 'text/plain' });

        // Generate a URL for the file
        const url = URL.createObjectURL(blob);

        // Create a temporary anchor element for the download
        const a = document.createElement('a');
        a.download = 'surveydefinition.txt'; // set the desired filename
        a.href = url;

        // Simulate a click on the anchor to initiate the download
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // Release the object URL to free up memory
        URL.revokeObjectURL(url);
        dispatch(loadToaster({ type: 'success', title: '', msg: 'Downloaded SucessFully' }))

    }

    const renderDialogFooter = () => {
        return (
            <div className='row'>
                <div className='col-xs-7 col-sm-7 col-md-7 col-lg-7'>

                </div>
                <div className='col-xs-5 col-sm-5 col-md-5 col-lg-5'>
                    <Button name='yes' label='Download'
                        icon="pi pi-download"
                        className='p-button-sm custom-primary-button font-13'
                        onClick={onDownloadClick}>

                    </Button>
                </div>

            </div>
        );
    }
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters };
        _filters2["global"].value = value;
        console.log(_filters2, "onChange trigger");
        setFilters(_filters2);
        setGlobalFilterValue(value);
    };
    const renderHeader2 = () => {
        return (
            <div className="row">
                <div className="col-6 d-flex justify-content-start">
                    <span></span>
                </div>
                <div className="col-6 d-flex justify-content-end">
                    <span className="p-input-icon-right w-100" style={{ textAlign: "right" }}>
                        <i className="pi pi-search" />
                        <InputText
                            value={globalFilterValue}
                            onChange={onGlobalFilterChange}
                            placeholder="Search Survey"
                            style={{ fontSize: '14px' }}
                        />
                    </span>
                </div>
            </div>
        )
    }
    const header2 = renderHeader2();

    return (
        <div>
            <div className="card" id="survey-list">
                <DataTable
                    style={{ fontSize: '14px', 'marginBottom': '50px' }}
                    header={header2}
                    filters={filters}
                    paginator
                    responsiveLayout="scroll"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    rows={10}
                    rowsPerPageOptions={[10, 20, 50]}
                    value={products}
                    selectionMode="single"
                    selection={selectedProduct}
                    onSelectionChange={(e) => setSelectedProduct(e.value)}
                    onRowDoubleClick={handleRowDoubleClick}
                    frozenHeader
                >


                    <Column field="survey_code" sortable header="Survey Code" bodyClassName="lowercase-text" />
                    <Column field="survey_description" header="Survey Name" sortable/>
                    <Column field="created_on" header="Created On" sortable/>
                    <Column
                        headerStyle={{ width: '4rem', textAlign: 'center' }}
                        bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                        header="Survey Definition" body={(rowData) => actionBodyTemplate(rowData)}
                    />
                </DataTable>
            </div>
            <Dialog header="Showing Survey Definition"
                contentStyle={{ height: '350px' }}
                style={{ width: '50vw' }}
                visible={showDialog}
                onHide={hideDialogBox}
                footer={renderDialogFooter}
                headerClassName="custom-header-style"

            >
                <p style={{
                    maxHeight: '100%', overflowY: 'auto',
                    wordWrap: 'break-word', fontSize: '14px',
                    whiteSpace: 'pre-wrap'

                }}>
                    {surveydefinition && JSON.stringify(JSON.parse(surveydefinition), null, 2)}
                </p>            </Dialog>
        </div>
    );
}


export default DataTableDemo;
