import { SHOW_TOAST } from "./toaster-store-constants"

export const loadToaster = ({ type, title, msg }) => (dispatch) => {
    dispatch({
        type: SHOW_TOAST,
        payload: {
            show: true,
            type,
            title,
            msg
        },
    });
};