import React, { useState, useEffect } from 'react';
import Button from '../../../shared-components/Button/custom-button';
import Panel from '../../../shared-components/Panel/custom-panel';
import { useHistory, useLocation } from 'react-router-dom';
import { fetchSurveyDefinition, saveOrUpdateSurvey } from '../survey-store/survey-actions';
import { useDispatch, useSelector } from 'react-redux';
import { getSurveyDefinitionData, onEditSurveyData } from '../survey-store/survey-action-creators';
import CustomCard from '../../../shared-components/Card/boot-card';

const EditSurvey = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const data = location.state.data;
    const { surveydefinition, onEditSurveyChange } = useSelector((state) => state.surveyDetails);
    const [textareaHeight, setTextareaHeight] = useState(0); // set an initial height for the textarea

    useEffect(() => {
        function updateTextareaHeight() {
            const screenHeight = window.innerHeight;
            let newHeight = screenHeight - 375;
            setTextareaHeight(`${newHeight}px`);
        }
        updateTextareaHeight(); // update the height on mount
        window.addEventListener('resize', updateTextareaHeight); // listen for screen size changes
        return () => {
            window.removeEventListener('resize', updateTextareaHeight); // remove event listener on unmount
        };
    }, []);
    useEffect(() => {
        dispatch(fetchSurveyDefinition(data?.survey_id));
        return () => {
            dispatch(getSurveyDefinitionData(""));

        }

    }, [])

    useEffect(() => {
        if (surveydefinition) {
            setFormValues((prevValues) => ({
                ...prevValues,
                surveyDefinition: surveydefinition,
            }));
        }
    }, [surveydefinition]);

    const [formValues, setFormValues] = useState({
        survey_code: data.survey_code,
        survey_description: data.survey_description,
        surveyDefinition: surveydefinition,
    });


    const [formErrors, setFormErrors] = useState({
        survey_code: '',
        survey_description: '',
        surveyDefinition: '',
    });

    const validateForm = () => {
        let isValid = true;
        const errors = {};

        if (!formValues.survey_code) {
            errors.survey_code = 'Survey code is required';
            isValid = false;
        }

        if (!formValues.survey_description) {
            errors.survey_description = 'Survey name is required';
            isValid = false;
        }

        if (!formValues.surveyDefinition) {
            errors.surveyDefinition = 'Survey definition is required';
            isValid = false;
        }
        else {
            try {
                JSON.parse(formValues.surveyDefinition);
            } catch (e) {
                errors.surveyDefinition = 'Survey definition must be a valid JSON string';
                isValid = false;
            }
        }


        setFormErrors(errors);

        return isValid;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        dispatch(onEditSurveyData(true));
        setFormValues({
            ...formValues,
            [name]: value,
        });
        setFormErrors({
            ...formErrors,
            [name]: '',
        });
    };

    const handleSave = () => {
        if (validateForm()) {
            // save the form values
            let postObject = {
                surveyId: data.survey_id,
                createdby: data.created_by,
                surveyDefinition: formValues.surveyDefinition,
                surveycode: formValues.survey_code,
                surveydescription: formValues.survey_description
            }

            dispatch(saveOrUpdateSurvey(postObject));
        }
    };
    const handleBack = () => {
        history.goBack();
    };
    return (
        <div className='col-12 project-survey'>
            <CustomCard
            header={ <div className='row' style={{ 'marginBottom': '10px', 'alignItems': 'center' }}>
            <div className='col-xs-12 col-sm-6'>
                <span style={{ 'fontWeight': 'bold', 'fontSize': '18px' }}>Edit Survey</span>
            </div>
            <div className='col-xs-12 col-sm-6' style={{ textAlign: 'right' }}>
                <Button
                    name='back'
                    label='Back'
                    className='p-button-sm custom-primary-button font-13'
                    onClick={handleBack}
                    icon='pi pi-arrow-left'
                />
                <Button
                    name='saveSurvey'
                    label='Save'
                    className='p-button-sm custom-accent-button font-13'
                    onClick={handleSave}
                    icon='pi pi-check-square'
                    disabled={!onEditSurveyChange}
                />
            </div>
        </div>}
            >              

                <div className='row m-3'>
                    <div className='col-12'></div>
                    <form>
                        <div className='row'>
                            <div className='col-6'>
                                <label style={{ 'fontWeight': 'bold', 'fontSize': '14px' }}>Survey Code</label>
                                <input
                                    style={{ 'fontSize': '14px' }}
                                    type='text'
                                    className={`form-control ${formErrors.survey_code ? 'is-invalid' : ''}`}
                                    id='survey_code'
                                    name='survey_code'
                                    value={formValues.survey_code}
                                    placeholder='Enter Survey Code'
                                    onChange={handleInputChange}
                                />
                                {formErrors.survey_code && <div className='invalid-feedback'>{formErrors.survey_code}</div>}
                            </div>
                            <div className='col-6'>
                                <label style={{ 'fontWeight': 'bold', 'fontSize': '14px' }}>Survey Name</label>
                                <input
                                    style={{ 'fontSize': '14px' }}
                                    type='text'
                                    className={`form-control ${formErrors.survey_description ? 'is-invalid' : ''}`}
                                    id='survey_description'
                                    name='survey_description'
                                    value={formValues.survey_description}
                                    onChange={handleInputChange}
                                />
                                {formErrors.survey_description && <div className='invalid-feedback'>{formErrors.survey_description}</div>}
                            </div>
                        </div>
                        <div className='row' style={{ 'marginTop': '10px' }}>
                            <div className='col-12'>
                                <label style={{ 'fontWeight': 'bold', 'fontSize': '14px' }}>Survey Definition</label>
                                <textarea
                                    style={{ 'fontSize': '14px', height: textareaHeight }}
                                    className={`form-control ${formErrors.surveyDefinition ? 'is-invalid' : ''}`}
                                    id='surveydefinition'
                                    name='surveyDefinition'
                                    rows='10'
                                    value={formValues.surveyDefinition}
                                    placeholder='Enter Survey Definition'
                                    onChange={handleInputChange}
                                ></textarea>
                                {formErrors.surveyDefinition && <div className='invalid-feedback'>{formErrors.surveyDefinition}</div>}
                            </div>
                        </div>

                    </form>


                </div >
            </CustomCard>
        </div >
    );
}

export default EditSurvey;




