import axios from "axios";
import { API_END_POINT } from "../../../../config";
import httpUtil from "../../../../utlities/http-util";
import { startSpinner, stopSpinner } from "../../../shared-components/custom-spinner/custom-spinner-store/spinner-actions";
import { loadToaster } from "../../../shared-components/toaster/toaster-actions-creators";
import { getDashboardDataHeaders, getSurveyDetailsInDashboard } from "./dashboard-actions-creators";

export const fetchDashboardHeadersResponse = (postObject) => {
    return async (dispatch) => {
        try {
            dispatch(startSpinner());

            const response = await httpUtil.get(`/api/dashboard/getdashboardhead/${postObject}`);
            // handle the response data here   
        
            dispatch(getDashboardDataHeaders(response.data));
            dispatch(stopSpinner());


        } catch (err) {
            dispatch(stopSpinner());
        }

    }
}

export const fetchSurveyDetailsInDashboard = (postObject) => {
    return async (dispatch) => {
        try {
            dispatch(startSpinner());

            const response = await httpUtil.get(`/api/dashboard/getdashboard1/${postObject}`);
            // handle the response data here   
          
            dispatch(getSurveyDetailsInDashboard(response.data.surveys));
            dispatch(stopSpinner());


        } catch (err) {
            dispatch(stopSpinner());
        }

    }
}
export const fetchSurveyDetailsInDashboardWithCustomDate = (postObject) => {
    return async (dispatch) => {
        try {
            dispatch(startSpinner());

            const response = await httpUtil.get(`/api/dashboard/getdashboard2/${postObject}`);
            // handle the response data here   
          
            dispatch(getSurveyDetailsInDashboard(response.data.surveys));
            dispatch(stopSpinner());


        } catch (err) {
            dispatch(stopSpinner());
        }

    }
}