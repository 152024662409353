import { FETCH_SURVEY_DETAILS_IN_REPONSE, ON_CHANGE_DATE_PICKER, STORE_POST_OBJECT_DATE_STRING } from "./response-store-constants";

export function getSurveyDetailsInResponse(data){
    return{
        type:FETCH_SURVEY_DETAILS_IN_REPONSE,
        payload:{
            surveyListInResponse:data
        }
    }
}

export function getDateStringValue(data){
    return{
        type:STORE_POST_OBJECT_DATE_STRING,
        payload:{
            getDateStringFromResponse:data
        }
    }
}
export function onChangeDatePicker(data){
    return{
        type:ON_CHANGE_DATE_PICKER,
        payload:{
            datePickerChanged:data
        }
    }
}