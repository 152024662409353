import { TEST, USER_DETAILS } from "./login-store-constants";

export function TestAction(data) {
    return {
        type: TEST,
        payload: {
            test: data
        }
    }
}
export function fetchLoginDetails(data) {
    return {
        type: USER_DETAILS,
        payload: {
            userLoginDetails: data
        }
    }
}