
import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './dashboard-data-table.css'
import { useHistory } from 'react-router-dom';
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../shared-components/Button/custom-button';
import PredefinedDateRangePicker from '../../../../shared-components/date-range-picker/date-range-picker';
import { onChangeDatePicker } from '../dashboard-actions-creators';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode,FilterOperator } from "primereact/api";

const DashboardDataTable = ({ data }) => {
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const [showDialog, setShowDialog] = useState(false);
    const { datePickerChanged, getDateStringFromResponse } = useSelector((state) => state.responseDetails);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        survey_description: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        response_count: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },

    });

    useEffect(() => {
        // if (data && data.length)
        setProducts(data)
    }, [data]);


    const handleSettingsClick = (rowData) => {
        console.log('Selected row data:', rowData);
        setSelectedProduct(rowData);

        setShowDialog(true);

    }

    const actionBodyTemplate = (rowData) => {
        return (
            <Button type="button"
                icon="pi pi-download"
                className="p-button-sm"
                style={{ width: '2rem', fontSize: 'small', height: '2rem' }}
                onClick={() => handleSettingsClick(rowData)} />
        );
    }


    const hideDialogBox = () => {
        setShowDialog(false);
        dispatch(onChangeDatePicker(false));

    }
    const onDownloadClick = () => {
        let postObject = {
            survey_id: selectedProduct?.survey_id,
            dateString: getDateStringFromResponse
        }
        // dispatch(exportSurveyDataToExcel(postObject));
        dispatch(onChangeDatePicker(false));
        setShowDialog(false);

    }

    const renderDialogFooter = () => {
        return (
            <div className='row'>
                <div className='col-xs-7 col-sm-7 col-md-7 col-lg-7'>

                </div>
                <div className='col-xs-5 col-sm-5 col-md-5 col-lg-5'
                    // style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
                    style={{ alignItems: 'center' }}

                >
                    <Button name='yes' label='Download'
                        icon="pi pi-download"
                        disabled={!datePickerChanged}
                        className='p-button-sm custom-primary-button font-13'
                        onClick={onDownloadClick}>
                    </Button>
                </div>
            </div>

        );
    }
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters };
        _filters2["global"].value = value;
        console.log(_filters2, "onChange trigger");
        setFilters(_filters2);
        setGlobalFilterValue(value);
    };
    const renderHeader2 = () => {
        return (
            <div className="row">
                <div className="col-6 d-flex justify-content-start">
                    <span></span>
                </div>
                <div className="col-6 d-flex justify-content-end">
                    <span className="p-input-icon-right w-100" style={{ textAlign: "right" }}>
                        <i className="pi pi-search" />
                        <InputText
                            value={globalFilterValue}
                            onChange={onGlobalFilterChange}
                            placeholder="Search Survey"
                            style={{ fontSize: '14px'}}
                        />
                    </span>
                </div>
            </div>
        )
    }
    const header2 = renderHeader2();
    return (
        <div>
            <div className="card">
                <DataTable
                    header={header2}
                    filters={filters}
                    value={products}
                    style={{ fontSize: '14px', 'marginBottom': '50px' }}
                    selectionMode="single"
                    // selection={selectedProduct}
                    // onSelectionChange={(e) => setSelectedProduct(e.value)}
                    frozenHeader
                    paginator
                    responsiveLayout="scroll"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    rows={10}
                    rowsPerPageOptions={[10, 20, 50]}
                    globalFilterFields={['response_count']}
                >
                    <Column field="survey_code" sortable header="Survey Code" bodyClassName="lowercase-text" />
                    <Column field="survey_description" header="Survey Name" sortable/>
                    <Column field="response_count" header="Response Count" sortable 
                    filterField="response_count"/>

                </DataTable>
            </div>
            <div>
                <Dialog header="Download Responses"
                    // contentStyle={{ height: '400px' }}
                    style={{ width: '50vw' }}
                    visible={showDialog}
                    onHide={hideDialogBox}
                    footer={renderDialogFooter}
                    headerClassName="custom-header-style"

                >
                    <div style={{
                        maxHeight: '100%', overflowY: 'auto',
                        wordWrap: 'break-word', fontSize: '14px'

                    }}>
                        <PredefinedDateRangePicker />
                    </div>
                </Dialog>
            </div>

        </div>
    );
}


export default DashboardDataTable;
