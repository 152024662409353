import { FETCH_SURVEY_DETAILS_IN_REPONSE, ON_CHANGE_DATE_PICKER, STORE_POST_OBJECT_DATE_STRING } from "./response-store-constants";

const intialState = {
    surveyListInResponse: [],
    getDateStringFromResponse: '',
    datePickerChanged: false
}
const ResponseReducer = (state = intialState, action) => {
    switch (action.type) {
        case FETCH_SURVEY_DETAILS_IN_REPONSE:
            return {
                ...state,
                surveyListInResponse: action.payload.surveyListInResponse
            }
        case STORE_POST_OBJECT_DATE_STRING:
            return {
                ...state,
                getDateStringFromResponse: action.payload.getDateStringFromResponse
            }
        case ON_CHANGE_DATE_PICKER:
            return {
                ...state,
                datePickerChanged: action.payload.datePickerChanged
            }
        default:
            return state;
    }
}
export default ResponseReducer;