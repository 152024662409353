import { FETCH_DASHBOARD_HEADER_DATA, ON_CHANGE_DATE_PICKER, FETCH_DASHBOARD_SURVEY_RESPONSE } from "./dashboard-store-constants";

const intialState = {
    getDashboardData: {},
    getDateStringFromResponse: '',
    datePickerChanged: false,
    getDashboardSurveyListResponse:[]
}
const DashboardReducer = (state = intialState, action) => {
    switch (action.type) {
        case FETCH_DASHBOARD_HEADER_DATA:
            return {
                ...state,
                getDashboardData: action.payload.getDashboardData
            }
        case FETCH_DASHBOARD_SURVEY_RESPONSE:
            return {
                ...state,
                getDashboardSurveyListResponse: action.payload.getDashboardSurveyListResponse
            }
        case ON_CHANGE_DATE_PICKER:
            return {
                ...state,
                datePickerChanged: action.payload.datePickerChanged
            }
        default:
            return state;
    }
}
export default DashboardReducer;