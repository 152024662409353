import { SHOW_TOAST } from "./toaster-store-constants";

const initialState={
    show:false,
    type:'',
    title:'',
    msg:''
};

export const ToastReducer= (state=initialState,action)=>{
    switch (action.type) {
        case SHOW_TOAST:
            return{
                show:action.payload.show,
                type:action.payload.type,
                title:action.payload.type,
                msg:action.payload.msg
            };
        
        default:
            return state;
    }
}