import { Button } from 'primereact/button';
import React from 'react'
import './custom-button.css'
const CustomButton = (props) => {
    const click = (e) => {
        if (props.onClick)
            props.onClick(e);
    }
    const finalClass = "".concat(" ", props.className || " ")

    return (
        <Button
            id={"button_" + props.name}
            className={finalClass}
            label={props.label}
            onClick={click}
            type={props.type}
            disabled={props.disabled || false}
            style={props.style}
            icon={props.icon || ""}
            tooltip={props.tooltip}
            tooltipOptions={props.tooltipOptions}
        />
    )
}

export default CustomButton