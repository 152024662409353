import { combineReducers } from "redux";
import DashboardReducer from "../components/Home/Dashboard/dashboard-store/dashboard-reducers";
import ResponseReducer from "../components/Home/Responses/response-store/response-reducers";
import SurveyReducer from "../components/Home/Survey/survey-store/survey-reducers";
import LoginReducer from "../components/Login/login-store/login-reducers";
import SpinnerReducer from "../components/shared-components/custom-spinner/custom-spinner-store/spinner-reducers";
import { ToastReducer } from "../components/shared-components/toaster/toaster-reducers";

const rootReducer = combineReducers({
    spinner: SpinnerReducer,
    login: LoginReducer,
    surveyDetails: SurveyReducer,
    responseDetails: ResponseReducer,
    toaster: ToastReducer,
    dashboardDetails:DashboardReducer
  });

  export default rootReducer;