import { GET_SURVEY_DEFINITION_BY_ID, GET_SURVEY_LIST, ON_EDIT_SURVEY_CHANGE } from "./survey-store-constants";

export function getSurveyListData(data) {
    return {
        type: GET_SURVEY_LIST,
        payload: {
            surveyList: data
        }

    }
}
export function getSurveyDefinitionData(data) {
    return {
        type: GET_SURVEY_DEFINITION_BY_ID,
        payload: {
            surveydefinition: data
        }

    }
}

export function onEditSurveyData(data) {
    return {
        type: ON_EDIT_SURVEY_CHANGE,
        payload: {
            onEditSurveyChange: data
        }

    }
}