import React from 'react'
import "./Footer.css"
const Footer = () => {
  return (
    <div className='footer'>
    Pvlaue Survey - Version 1.0.0
      </div>
  )
}

export default Footer