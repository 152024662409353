import { TEST, USER_DETAILS } from "./login-store-constants";

const initialState = {
    test: "test123",
    userLoginDetails: {}
};

const LoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case TEST:
            return {
                ...state,
                test: action.payload.test
            };
        case USER_DETAILS:
            return {
                ...state,
                userLoginDetails: action.payload.userLoginDetails
            };
        default:
            return state;
    }
};


export default LoginReducer;
