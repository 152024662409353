import React, { useState, useRef, useEffect } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { getDateStringValue, onChangeDatePicker } from '../../Home/Responses/response-store/response-actions-creators';
import Button from '../Button/custom-button';
import { useDispatch, useSelector } from 'react-redux';
import { exportSurveyDataToExcel } from '../../Home/Responses/response-store/response-actions';

const PredefinedDateRangePicker = (props) => {
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const [showDateRange, setShowDateRange] = useState(false);
  const { datePickerChanged } = useSelector((state) => state.responseDetails);

  const datePickerRef = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
      setShowDateRange(false);
    }
  };
  const handleSelect = (ranges) => {
    setDateRange(ranges.selection);
    const startDate = ranges.selection.startDate.toLocaleDateString();
    const endDate = ranges.selection.endDate.toLocaleDateString();
    const formattedStartDate = formatDateString(startDate);
    const formattedEndDate = formatDateString(endDate);
    const formattedDateRange = `${formattedStartDate}/${formattedEndDate}`;
    // storing the value in redux-store

    dispatch(getDateStringValue(formattedDateRange));
    dispatch(onChangeDatePicker(true));
  };

  // to change the selected data into YYYY-MM-DD format
  const formatDateString = (dateString) => {
    const [month, day, year] = dateString.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  };

  // selection which shows in the input box
  const formatRange = (range) => {
    const startDate = range.startDate.toLocaleDateString();
    const endDate = range.endDate.toLocaleDateString();
    return `${startDate} - ${endDate}`;
  };

  const onDownloadClick = () => {
    console.log("Child");
    props.onDownloadClick();

  }

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ display: 'flex', alignItems: 'start', justifyContent: 'start' }}>
        <div style={{ marginRight: '10px' }}>
          <label style={{ fontWeight: '400'}} htmlFor="date-range-picker">Select Date Range</label>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ position: 'relative' }} onClick={() => setShowDateRange(!showDateRange)}>
              <input
                type="text"
                id="date-range-picker"
                style={{ marginTop: '2px', marginRight: '5px', paddingRight: '25px', height:'40px', border:'2px solid silver', borderRadius:'5px'  }}
                value={formatRange(dateRange)}
                readOnly
                onClick={() => setShowDateRange(!showDateRange)}
              />
              <i
                className={`pi pi-calendar-plus`}
                onClick={() => setShowDateRange(!showDateRange)}
                style={{ marginTop: '3px', cursor: 'pointer', position: 'absolute', right: '15px', top: '50%', transform: 'translateY(-50%)' }}
              />
            </div>
            
            {/* 
            <i className={`pi pi-chevron-${showDateRange ? 'up' : 'down'}`} style={{ cursor: 'pointer' }}
            /> 
            */}

            <Button
            name='yes'
            label='Download'
            icon="pi pi-download"
            disabled={!datePickerChanged}
            className='p-button-sm custom-primary-button font-13'
            onClick={onDownloadClick}
            style={{height:'40px'}}
          />

          </div>
          {showDateRange && (
            <div ref={datePickerRef}
              style={{
                border: "1px solid #ccc",
                padding: "3px", borderRadius: "5px", borderColor: 'silver'
              }}
            >
              <DateRange
                ranges={[dateRange]}
                onChange={handleSelect}
                minDate={new Date('2022-01-01')}
                className="calendarElement"
              />
            </div>
          )}
        </div>
        
      </div>
    </div>
  );

};

export default PredefinedDateRangePicker;
