
import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './response-data-table.css'
import { useHistory } from 'react-router-dom';
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../shared-components/Button/custom-button';
import PredefinedDateRangePicker from '../../../../shared-components/date-range-picker/date-range-picker';
import { exportSurveyDataToExcel } from '../response-actions';
import { onChangeDatePicker } from '../response-actions-creators';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from "primereact/api";
import { startSpinner, stopSpinner } from '../../../../shared-components/custom-spinner/custom-spinner-store/spinner-actions';

const ResponseDataTable = ({ data }) => {
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const [showDialog, setShowDialog] = useState(false);
    const { datePickerChanged, getDateStringFromResponse } = useSelector((state) => state.responseDetails);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        survey_description: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    });

    useEffect(() => {
        // if (data && data.length)
        setProducts(data)
    }, [data]);


    const handleSettingsClick = (rowData) => {
        console.log('Selected row data:', rowData);
        setSelectedProduct(rowData);

        setShowDialog(true);

    }

    const actionBodyTemplate = (rowData) => {
        return (
            <Button type="button"
                icon="pi pi-download"
                className="p-button-sm"
                style={{ width: '2rem', fontSize: 'small', height: '2rem' }}
                onClick={() => handleSettingsClick(rowData)} />
        );
    }


    const hideDialogBox = () => {
        setShowDialog(false);
        dispatch(onChangeDatePicker(false));

    }
    const onDownloadClick = () => {
        dispatch(startSpinner());
        let postObject = {
            survey_id: selectedProduct?.survey_id,
            dateString: getDateStringFromResponse
        }
        dispatch(exportSurveyDataToExcel(postObject));
        dispatch(onChangeDatePicker(false));
        setShowDialog(false);
        dispatch(stopSpinner());
    }

    const renderDialogFooter = () => {
        return (
            <div className='row'>
                <div className='col-xs-7 col-sm-7 col-md-7 col-lg-7'>

                </div>
                <div className='col-xs-5 col-sm-5 col-md-5 col-lg-5'
                    // style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
                    style={{ alignItems: 'center' }}

                >
                    {/* 
                    <Button name='yes' label='Download'
                        icon="pi pi-download"
                        disabled={!datePickerChanged}
                        className='p-button-sm custom-primary-button font-13'
                        onClick={onDownloadClick}>
                    </Button>
                    */}
                </div>
            </div>

        );
    }
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters };
        _filters2["global"].value = value;
        console.log(_filters2, "onChange trigger");
        setFilters(_filters2);
        setGlobalFilterValue(value);
    };
    const renderHeader2 = () => {
        return (
            <div className="row">
                <div className="col-6 d-flex justify-content-start">
                    <span></span>
                </div>
                <div className="col-6 d-flex justify-content-end">
                    <span className="p-input-icon-right w-100" style={{ textAlign: "right" }}>
                        <i className="pi pi-search" />
                        <InputText
                            value={globalFilterValue}
                            onChange={onGlobalFilterChange}
                            placeholder="Search Survey"
                            style={{ fontSize: '14px' }}
                        />
                    </span>
                </div>
            </div>
        )
    }
    const header2 = renderHeader2();
    return (
        <div>
            <div className="card">
                <DataTable
                    header={header2}
                    filters={filters}
                    value={products}
                    style={{ fontSize: '14px', 'marginBottom': '50px' }}
                    selectionMode="single"
                    // selection={selectedProduct}
                    // onSelectionChange={(e) => setSelectedProduct(e.value)}
                    frozenHeader
                    paginator
                    responsiveLayout="scroll"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    rows={10}
                    rowsPerPageOptions={[10, 20, 50]}
                >
                    <Column field="survey_code" sortable header="Survey Code" bodyClassName="lowercase-text" />
                    <Column field="survey_description" header="Survey Name" sortable/>
                    <Column
                        headerStyle={{ width: '4rem', textAlign: 'center' }}
                        bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                        header="Download Responses" body={(rowData) => actionBodyTemplate(rowData)}
                    />
                </DataTable>
            </div>
            <div>
                <Dialog header="Download Responses"
                    contentStyle={{ height: '100%' }}
                    style={{ width: '500px' }}
                    visible={showDialog}
                    onHide={hideDialogBox}
                    footer={renderDialogFooter}
                    headerClassName="custom-header-style">
                    
                    <div className='containerV'  style={{ fontSize: '13px'}}>
                        <PredefinedDateRangePicker onDownloadClick={onDownloadClick} />
                    </div>
                </Dialog>
            </div>

        </div>
    );
}


export default ResponseDataTable;
