import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '../shared-components/Button/custom-button';
import { fetchTestCall, fetchUserDetals } from './login-store/login-actions';
import Icon from '../../Images/ps_logo.png';
import { loadToaster } from '../shared-components/toaster/toaster-actions-creators';
import './Login.css'; 

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    window.history.go(1);
  };
  function isValidEmail(email) {
    // You can use a regular expression or a library like validator.js to validate the email
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }
  const validateEmail = () => {
    if (!email) {
      setEmailError('Email is required');
      return false;
    }
    const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (!emailPattern.test(email)) {
      setEmailError('Invalid email');
      return false;
    }
    setEmailError(null);
    return true;
  };

  const validatePassword = () => {
    if (!password) {
      setPasswordError('Password is required');
      return false;
    }
    if (password.length < 8) {
      setPasswordError('Password must be at least 8 characters');
      return false;
    }
    setPasswordError(null);
    return true;
  };
  const handleUsernameChange = (event) => {
    setEmail(event.target.value);
    if (!isValidEmail(event.target.value)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
    // setEmailError('');
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordError('');
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    const isEmailValid = validateEmail();
    const isPasswordValid = validatePassword();
    if (isEmailValid && isPasswordValid) {
      dispatch(fetchTestCall());
      let getResponse = await dispatch(fetchUserDetals(`${email}/${password}`));
      if (getResponse.user_id == 0) {
        setEmailError(getResponse.message);
        dispatch(loadToaster({ type: 'error', title: '', msg: getResponse.message }))

      }
      else {
        localStorage.setItem('Authentication', JSON.stringify(getResponse));
        history.push('/home/dashboard');
        dispatch(loadToaster({ type: 'success', title: '', msg: 'Login Successful' }))
      }
    }
  };
  return (
    <div className="row vh-100 bg">
        <div className="col-sm-10 col-md-8 col-lg-4 mx-auto d-table">
          <div className="d-table-cell align-middle">
            <div className="card">
              <div className="card-body">
              <div className="text-center mt-0">
                    <img src={Icon} alt="icon" className="logo" />
                    {/* <h2 className="h2">NielsenIQ | Field Login Logout</h2> */}
                    {/* <p className="lead">Sign in to your account to continue</p> */}
                  </div>
                  <div>
                    <form onSubmit={handleLogin}>
                      <div className="mb-3">
                        <label className="form-label"
                          style={{ 'fontWeight': 'bold', 'fontSize': '14px' }}>Email</label>
                        <input
                          style={{ 'fontSize': '14px' }}
                          className={`form-control form-control-lg ${emailError ? 'is-invalid' : ''}`}
                          type="email"
                          name="email"
                          placeholder="Enter your email"
                          value={email}
                          onChange={handleUsernameChange}
                        />
                        {emailError && <div className="invalid-feedback" style={{ display: 'block' }}>{emailError}</div>}
                      </div>
                      <div className="mb-3">
                        <label className="form-label"
                          style={{ 'fontWeight': 'bold', 'fontSize': '14px' }}>Password</label>
                        <input
                          style={{ 'fontSize': '14px' }}
                          className={`form-control form-control-lg ${passwordError ? 'is-invalid' : ''}`}
                          type="password"
                          name="password"
                          placeholder="Enter your password"
                          value={password}
                          onChange={handlePasswordChange}
                        />
                        {passwordError && <div className="invalid-feedback">{passwordError}</div>}
                      </div>
                      {/* <small className="mb-3">
                        <a href="pages-reset-password.html">Forgot password?</a>
                      </small> */}
                      <div>
                        {/* <label className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue="remember-me"
                            name="remember-me"
                            defaultChecked=""
                          />
                          <span className="form-check-label">
                            Remember me next time
                          </span>
                        </label> */}
                      </div>
                      <div className="text-center mt-3">
                        {/* <a href="index.html" className="btn btn-lg btn-primary">
                        Sign in
                      </a> */}
                        {/* <button type="submit" class="btn btn-lg btn-primary">Sign in</button> */}
                        <br></br>
                        <Button
                          name='login'
                          label='Sign In'
                          className='custom-primary-button buttonW font-11'
                          type='submit'
                          icon='pi pi-sign-in'
                          size='small'
                        />
                     </div>
                    </form>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>

   

  );
}

export default Login;
