import { FETCH_DASHBOARD_HEADER_DATA,FETCH_DASHBOARD_SURVEY_RESPONSE, ON_CHANGE_DATE_PICKER, STORE_POST_OBJECT_DATE_STRING } from "./dashboard-store-constants";

export function getDashboardDataHeaders(data){
    return{
        type:FETCH_DASHBOARD_HEADER_DATA,
        payload:{
            getDashboardData:data
        }
    }
}
export function getSurveyDetailsInDashboard(data){
    return{
        type:FETCH_DASHBOARD_SURVEY_RESPONSE,
        payload:{
            getDashboardSurveyListResponse:data
        }
    }
}
export function getDateStringValue(data){
    return{
        type:STORE_POST_OBJECT_DATE_STRING,
        payload:{
            getDateStringFromResponse:data
        }
    }
}
export function onChangeDatePicker(data){
    return{
        type:ON_CHANGE_DATE_PICKER,
        payload:{
            datePickerChanged:data
        }
    }
}