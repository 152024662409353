import React, { useEffect, useState } from 'react'
import Button from '../../shared-components/Button/custom-button'
import Panel from '../../shared-components/Panel/custom-panel'
import Card from 'react-bootstrap/Card';
import './survey.css'
import Datatable from '../../shared-components/data-table/survey-data-table'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSurveyDetails } from './survey-store/survey-actions'
import { getSurveyListData } from './survey-store/survey-action-creators'
import Paginator from '../../shared-components/Paginator/paginator'
import { useHistory, useRouteMatch } from 'react-router-dom';
import browseHistory from '../../../routes/history'
import { loadToaster } from '../../shared-components/toaster/toaster-actions-creators'
import CustomCard from '../../shared-components/Card/boot-card'
const Survey = () => {
  const { surveyList } = useSelector((state) => state.surveyDetails);
  let { path, url } = useRouteMatch();
  const { userLoginDetails } = useSelector((state) => state.login);
  const [filteredList, setFilteredList] = useState([])
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('Authentication') !== null;
    if (isAuthenticated && userLoginDetails.user_id != undefined) {
      dispatch(fetchSurveyDetails(userLoginDetails.user_id));
    }
    return () => {
      dispatch(getSurveyListData([]));
    };
  }, [dispatch, userLoginDetails.user_id]);

  useEffect(() => {
    setFilteredList(surveyList);
  }, [surveyList])



  const CreateNewSurvey = () => {
    history.push('/home/survey/create-survey');
  }

  const handleSearch = (e) => {
    if (e.target.value === "") {
      setFilteredList(surveyList);
      return;
    }
    const filteredItems = surveyList.length && surveyList.filter((item) =>
      item?.survey_description?.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
    );
    setFilteredList(filteredItems);
  }
  const exportSurveyData = () => {
    const XLSX = require("xlsx");
    let data1 = [...surveyList];
    const data = data1.map(obj => {
      const { created_by, ...rest } = obj;
      return rest;
    });
    const worksheet = XLSX.utils.json_to_sheet(data);
    worksheet["!cols"] =
      [
        { wpx: 90 },
        { wpx: 120 },
        { wpx: 250 },
        { wpx: 100 },
        { wpx: 100 },
      ]
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Exported_Surveys.xlsx");
    dispatch(loadToaster({ type: 'success', title: '', msg: 'Downloaded SucessFully' }))

  }
  return (
    <div className='col-12 project-survey'>

<Card style={{ 'marginLeft': '22%', 'marginTop': '1%','marginRight':'5%'}}>
      <Card.Body>
      <div style={{ width:'20%', float: 'left', marginTop:'7px' }}>
        <h5 >Survey</h5>
        </div>

      <div className='col-xs-12 col-sm-6' style={{ textAlign: 'right', float:'right' }}>
          <Button
            name="exportSurveys"
            label="Export Surveys"
            className="p-button-sm custom-primary-button font-13"
            onClick={exportSurveyData}
            icon="pi pi-file"
            tooltip="Click to export all the Surveys"
            tooltipOptions={{ postion: 'top', mouseTrack: true, mouseTrackTop: 15 }}
          />
          <Button
            name="addNewSurvey"
            label="Add New"
            className="p-button-sm custom-accent-button font-13"
            onClick={CreateNewSurvey}
            icon="pi pi-plus-circle"
          />
        </div>
        </Card.Body>
        </Card>



        <div style={{ 'marginLeft': '22%', 'marginTop': '1%','marginRight':'5%'}}>
        {filteredList.length > 0 && <Datatable data={filteredList} />}
      </div>
      <br></br><br></br><br></br><br></br><br></br><br></br>

    </div>
  )
}

export default Survey