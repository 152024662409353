import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import "../components/Home/Sidebar/main.css";

// Importing components
import Dashboard from "../components/Home/Dashboard/Dashboard";
import Survey from "../components/Home/Survey/Survey";
import Responses from "../components/Home/Responses/Responses";
import CreateSurvey from "../components/Home/Survey/create-survey/create-survey";
import Login from "../components/Login/Login";
import Header from "../components/Home/Header/Header";
import Sidebar from "../components/Home/Sidebar/Sidebar";
import Footer from "../components/Home/Footer/Footer";
import EditSurvey from "../components/Home/Survey/edit-survey/edit-survey";
import { useDispatch } from "react-redux";
import { fetchLoginDetails } from "../components/Login/login-store/login-actions-creators";
import { createBrowserHistory } from 'history';

// Private Route component to check if user is authenticated
const PrivateRoute = ({ component: Component, ...rest }) => {
    const isAuthenticated = localStorage.getItem('Authentication') !== null;
    const dispatch = useDispatch();
    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchLoginDetails(JSON.parse(localStorage.getItem('Authentication'))));
        }
    }, [isAuthenticated]);

    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};

// Routing for all the components
const AppRoutes = () => {
    const history = createBrowserHistory();

    useEffect(() => {
        // Check if user is authenticated
        const isAuthenticated = localStorage.getItem("Authentication") !== null;
        if (!isAuthenticated) {
            localStorage.clear();
        }
    }, []);

    return (
        <Router history={history}>
            <Switch>
                <Route exact path="/login" component={Login} />
                <PrivateRoute path="/home" component={HomeRoutes} />
                <Redirect to="/login" />
            </Switch>
        </Router>
    );
};

// Routing for all the components inside the Home component
const HomeRoutes = ({ match }) => {
    return (
        <>
            <Header />
            <Sidebar />
            <Switch>
                <Route exact path={`${match.path}`}>
                    <Redirect to={`${match.path}/dashboard`} />
                </Route>
                <PrivateRoute
                    exact
                    path={`${match.path}/dashboard`}
                    component={Dashboard}
                />
                <PrivateRoute exact path={`${match.path}/survey`} component={Survey} />
                <PrivateRoute
                    exact
                    path={`${match.path}/response`}
                    component={Responses}
                />
                <PrivateRoute
                    exact
                    path={`${match.path}/survey/create-survey`}
                    component={CreateSurvey}
                />
                <PrivateRoute
                    exact
                    path={`${match.path}/survey/edit-survey/:id`}
                    component={EditSurvey}
                />
                <Redirect to={`${match.path}/dashboard`} />
            </Switch>
            <Footer />
        </>
    );
};

export default AppRoutes;
