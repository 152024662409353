import { SPINNER_STOP, SPINNER_START } from "./spinner-actions-constants";

export function startSpinner(){
    return{
        type: SPINNER_START
    }
}
export function stopSpinner(){
    return{
        type: SPINNER_STOP
    }
}
