import { GET_SURVEY_DEFINITION_BY_ID, GET_SURVEY_LIST, ON_EDIT_SURVEY_CHANGE } from "./survey-store-constants";

const initialState = {
    surveyList: [],
    surveydefinition: '',
    onEditSurveyChange: false
}
const SurveyReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SURVEY_LIST:
            return {
                ...state,
                surveyList: action.payload.surveyList
            }
        case GET_SURVEY_DEFINITION_BY_ID:
            return {
                ...state,
                surveydefinition: action.payload.surveydefinition
            }
        case ON_EDIT_SURVEY_CHANGE:
            return {
                ...state,
                onEditSurveyChange: action.payload.onEditSurveyChange
            }

        default:
            return state;
    }
}
export default SurveyReducer;