import axios from "axios";
import { API_END_POINT } from "../../../../config";
import httpUtil from "../../../../utlities/http-util";
import { startSpinner, stopSpinner } from "../../../shared-components/custom-spinner/custom-spinner-store/spinner-actions";
import { loadToaster } from "../../../shared-components/toaster/toaster-actions-creators";
import { getSurveyDetailsInResponse } from "./response-actions-creators";

export const fetchSurveyDetailsInResponse = (postObject) => {
    return async (dispatch) => {
        try {
            dispatch(startSpinner());

            const response = await httpUtil.get(`/api/survey/GetSurvey/${postObject}`);
            // handle the response data here   
            const modifiedSurveys = response.data.surveys.map(survey => {
                const createdOnDate = survey.created_on.split("T")[0];
                return { ...survey, created_on: createdOnDate };
            });
            dispatch(getSurveyDetailsInResponse(modifiedSurveys));
            dispatch(stopSpinner());


        } catch (err) {
            dispatch(stopSpinner());
        }

    }
}


export const exportSurveyDataToExcel = (postObject) => {
    return async (dispatch) => {
        try {
            dispatch(startSpinner());
            axios.get(`${API_END_POINT.BASE_URL}/api/response/GetResponse/${postObject.survey_id}/${postObject.dateString}`,
                { responseType: "arraybuffer", headers: { "Content-Type": "blob" } })
                .then(xml => {
                    if (xml.headers['content-length'] > 1) {
                        const url = window.URL.createObjectURL(new Blob([xml.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        let fileName1 = "Survey_Data.xlsx";
                        let fileName = fileName1;
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                        dispatch(loadToaster({type:'success',title:'',msg:'Downloaded SucessFully'}))
                        return 1;
                    }
                    // handleXmlResponse(xml);
                })
                .catch(error => console.error(error));

            // axios.get(`https://cidashboards.nielseniq.com/SurveyAPI/api/response/GetResponse/4/2023-01-01/2023-03-31`, {
            //     "Content-Type": "application/xml; charset=utf-8"
            // })
            //     .then((response) => {
            //         console.log('Your xml file as string', response.data);
            //         const workbook = convertXmlToWorkbook(response.data);
            //         downloadWorkbook(workbook);
            //     });
            dispatch(stopSpinner());

        } catch (err) {
            dispatch(stopSpinner());
            dispatch(loadToaster({type:'error',title:'',msg:'Something went Wrong while fetching the data'}))

        }

    }
}