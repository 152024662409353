import axios from "axios";
import { API_END_POINT } from "../../../config";
import { startSpinner, stopSpinner } from "../../shared-components/custom-spinner/custom-spinner-store/spinner-actions";
import { TestAction } from "./login-actions-creators";

export const fetchTestCall = () => {
    return (dispatch) => {
        try {
            // dispatch(startSpinner());
            dispatch(TestAction("ravikrishnapunnam@gmail.com"));

        } catch (err) {

        }

    };
};
export const fetchUserDetals = (postObject) => {
    return async (dispatch) => {
        try {
            dispatch(startSpinner());
            const response = await axios.get(`${API_END_POINT.BASE_URL}/api/User/Login/${postObject}`);
            let getDetails = response.data;
            if (getDetails.status == 100) {
                let obj = {
                    user_id: getDetails.user_id,
                    user_name: getDetails.user_name,
                    user_email: getDetails.user_email
                }
                dispatch(stopSpinner());
                return obj;
            }
            else {
                let obj = {
                    user_id: getDetails.user_id,
                    message: getDetails.message,
                }
                dispatch(stopSpinner());
                return obj;
            }

        } catch (err) {
            console.error(err);
            dispatch(stopSpinner());
            throw err; // Throw the error to the caller
        }
    };
};
