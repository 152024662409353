import React from 'react'
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import "./custom-spinner.css"
const CustomSpinner = () => {
    const { tasks: pendingTasks } = useSelector((state) => state.spinner)
    return (
        <div>
            {(pendingTasks > 0) &&
                <div className='parent'>
                    <Spinner animation='border' className='spinner' />
                </div>}


        </div>
    )
}

export default CustomSpinner