import React, { useEffect, useState } from 'react'
import './responses.css'
import Card from 'react-bootstrap/Card';
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom';
import ResponseDataTable from './response-store/response-data-table/response-data-table'
import { fetchSurveyDetailsInResponse } from './response-store/response-actions'
import { getSurveyDetailsInResponse } from './response-store/response-actions-creators'
import CustomCard from '../../shared-components/Card/boot-card'
const Responses = () => {
  const { surveyListInResponse } = useSelector((state) => state.responseDetails);
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const { userLoginDetails } = useSelector((state) => state.login);
  const [filteredList, setFilteredList] = useState([])
 
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('Authentication') !== null;
    if (isAuthenticated && userLoginDetails.user_id != undefined) {
      dispatch(fetchSurveyDetailsInResponse(userLoginDetails.user_id));

    }
    return () => {
      dispatch(getSurveyDetailsInResponse([]));
    };
  }, [dispatch, userLoginDetails.user_id]);

  useEffect(() => {
    setFilteredList(surveyListInResponse);
  }, [surveyListInResponse])


  const handleSearch = (e) => {
    if (e.target.value === "") {
      setFilteredList(surveyListInResponse);
      return;
    }
    const filteredItems = surveyListInResponse.length && surveyListInResponse.filter((item) =>
      item?.survey_description?.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
    );
    setFilteredList(filteredItems);
  }
  return (
    <div className='col-12 project-survey'>

<Card style={{ 'marginLeft': '22%', 'marginTop': '1%','marginRight':'5%'}}>
      <Card.Body>
      <div style={{ width:'20%', float: 'left', marginTop:'7px' }}>
        <h5 >Response</h5>
        </div>
        </Card.Body>
        </Card>
        <div style={{ 'marginLeft': '22%', 'marginTop': '1%','marginRight':'5%'}}>
        {filteredList.length > 0 && <ResponseDataTable data={filteredList} />}
      </div>
      <br></br><br></br><br></br><br></br><br></br><br></br>
    </div>

  )
}

export default Responses