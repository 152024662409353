import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

function CustomCard(props) {
    const { children, header, className } = props;

  return (
    <Card  style={{ 'marginLeft': '22%', 'marginTop': '1%','marginRight':'5%'}}>
      <Card.Header>{props.header}</Card.Header>
      {children}
    </Card>
  );
}

export default CustomCard;
