import React, { useEffect } from 'react';
import { Toast } from 'primereact/toast';
import { useSelector } from 'react-redux';

const ToastComponent = () => {
  const toaster = useSelector((state) => state.toaster);


  useEffect(() => {
    if (toaster.show) {
      toast.current.show({
        severity: toaster.type,
        summary: toaster.title,
        detail: toaster.msg,
        life:3000
      });
    } 
  }, [toaster]);

  const toast = React.createRef();

  return (
    <div>
      <Toast ref={toast} />
    </div>
  );
};

export default ToastComponent;