import { startSpinner, stopSpinner } from "./spinner-actions";
import { SPINNER_STOP, SPINNER_START } from "./spinner-actions-constants";

const initialState = {
    tasks: 0,
    start: startSpinner,
    stop: stopSpinner,
};

const SpinnerReducer = (state = initialState, action) => {
    switch (action.type) {
        case SPINNER_START: {
            return {
                ...state,
                tasks: state.tasks + 1,
            }
        }
        case SPINNER_STOP: {
            return {
                ...state,
                tasks: state.tasks - 1,
            }
        }
        default:
            return state;
    }
};

export default SpinnerReducer;
