import axios from "axios";
import { API_END_POINT } from "../../../../config";
import httpUtil from "../../../../utlities/http-util";
import { convertXmlToWorkbook, downloadWorkbook, downloadXmlAsExcel, handleExcelResponse, handleXmlResponse } from "../../../../utlities/work-book-generation";
import { startSpinner, stopSpinner } from "../../../shared-components/custom-spinner/custom-spinner-store/spinner-actions";
import { loadToaster } from "../../../shared-components/toaster/toaster-actions-creators";
import { getSurveyDefinitionData, getSurveyListData } from "./survey-action-creators";

export const fetchSurveyDetails = (postObject) => {
    return async (dispatch) => {
        try {
            dispatch(startSpinner());

            // const response = await axios.get(`${API_END_POINT.BASE_URL}/api/survey/GetSurvey/1`);
            const response = await httpUtil.get(`/api/survey/GetSurvey/${postObject}`);

            console.log(response);
            // handle the response data here   
            const modifiedSurveys = response.data.surveys.map(survey => {
                const createdOnDate = survey.created_on.split("T")[0];
                return { ...survey, created_on: createdOnDate };
            });
            dispatch(getSurveyListData(modifiedSurveys));
            dispatch(stopSpinner());

        } catch (err) {
            dispatch(stopSpinner());

        }

    }
}

export const fetchSurveyDefinition = (postObjectId) => {
    return async (dispatch) => {
        try {
            dispatch(startSpinner());
            const response = await httpUtil.get(`/api/survey/GetSurveyDefinition/${postObjectId}`);
            console.log(response);
            let string = JSON.stringify(response.data,null,2);
            dispatch(getSurveyDefinitionData(string));
            dispatch(stopSpinner());


        } catch (err) {
            dispatch(stopSpinner());

        }

    }
}

export const saveOrUpdateSurvey = (postObject) => {
    return async (dispatch) => {
        try {
            dispatch(startSpinner());
            const response = await httpUtil.post(`/api/survey/postsurvey`, postObject);
            console.log(response, "postcall");
            dispatch(stopSpinner());
            dispatch(loadToaster({type:'success',title:'',msg:'Data Saved SucessFully'}))


        } catch (err) {
            dispatch(stopSpinner());
            dispatch(loadToaster({type:'error',title:'',msg:'Something went Wrong, please try again later!'}))

        }

    }
}


