import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, HashRouter } from 'react-router-dom';
import Login from './components/Login/Login';
// import Home from './components/Home/Home';
import { Provider } from 'react-redux';
import store from './store/store';
import CustomSpinner from './components/shared-components/custom-spinner/custom-spinner';
import AppRoutes from './routes/routes';
import ToastComponent from './components/shared-components/toaster/toaster';

function App() {
  return (
    <Provider store={store}>
      <CustomSpinner />
      <ToastComponent/>
      {/* <HashRouter hashType='slash' basename={process.env.PUBLIC_URL}> */}
      <AppRoutes />

      {/* </HashRouter> */}
    </Provider>
  );
}

export default App;
