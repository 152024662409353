import axios from 'axios';

import { API_END_POINT } from "../config";


// Set up default headers
axios.defaults.headers.common['Content-Type'] = 'application/json';

// HTTP GET method
const get = async (url) => {
    try {
        const response = await axios.get(`${API_END_POINT.BASE_URL}${url}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(`HTTP Error ${response.status} - ${response.statusText}`);
        }
    } catch (error) {
        throw new Error(`Network Error - ${error.message}`);
    }
};

// HTTP POST method
const post = async (url, data) => {
    try {
        const response = await axios.post(`${API_END_POINT.BASE_URL}${url}`, data);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(`HTTP Error ${response.status} - ${response.statusText}`);
        }
    } catch (error) {
        throw new Error(`Network Error - ${error.message}`);
    }
};

export default { get, post };
