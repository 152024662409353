import React, { useState } from "react";
import DashboardImg from '../../../Images/dashboard.svg';
import SurveyImg from '../../../Images/survey.svg';
import ResponseImg from '../../../Images/response.svg';
import Icon from '../../../Images/ps_logo.png';



import { useLocation, useRouteMatch, NavLink } from "react-router-dom";
const Sidebar = () => {
    const location = useLocation();
    let { path, url } = useRouteMatch();

    const [closeMenu, setCloseMenu] = useState(false);

    const handleCloseMenu = () => {
        setCloseMenu(!closeMenu);
    };

    return (
        <div className={closeMenu === false ? "sidebar" : "sidebar active"} style={{marginTop:'5px'}}>

        {/* <div className={closeMenu === false ? "logoContainer" : "logoContainer active"}>
          <img src={Icon} alt="icon" className="logo" />
        </div> */}
        {/* <div style={{ height: "2px", width: "100%", backgroundColor: "white" }}></div> */}

        <div className={closeMenu === false ? "contentsContainer" : "contentsContainer active"} style={{ textAlign: 'center' }}>
          <ul style={{ display: 'flex', justifyContent: 'center', listStyleType: 'none', padding: 0, justifyContent: 'center' }}>

            <li style={{ display: 'inline-block', textAlign: 'center' }}>
              <NavLink activeClassName="active" to={`${url}/dashboard`}>
                <div style={{ display: 'flex', alignItems: 'center',paddingLeft:'40px' }}>
                  <img src={DashboardImg} alt="dashboard" style={{ width: '45px' }} />
                  <span>Dashboard</span>
                </div>
              </NavLink>
            </li>

            <li style={{ display: 'inline-block', textAlign: 'center' }}>
              <NavLink activeClassName="active" to={`${url}/survey`}>
                <div style={{ display: 'flex', alignItems: 'center',paddingLeft:'40px' }}>
                  <img src={SurveyImg} alt="survey" style={{  width: '45px' }} />
                  <span>Survey</span>
                </div>
              </NavLink>
            </li>

            <li style={{ display: 'inline-block', textAlign: 'center' }}>
              <NavLink activeClassName="active" to={`${url}/response`}>
                <div style={{ display: 'flex', alignItems: 'center',paddingLeft:'40px' }}>
                  <img src={ResponseImg} alt="response" style={{  width: '45px' }} />
                  <span>Response</span>
                </div>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      
    );
};

export default Sidebar;