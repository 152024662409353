import React, { useEffect, useState, useRef } from 'react'
import CustomCard from '../../shared-components/Card/boot-card';
import Card from 'react-bootstrap/Card';
import './dashboard.css'
import { getSurveyDetailsInResponse } from '../Responses/response-store/response-actions-creators';
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { fetchDashboardHeadersResponse, fetchSurveyDetailsInDashboard, fetchSurveyDetailsInDashboardWithCustomDate } from './dashboard-store/dashboard-actions';
import DashboardDataTable from './dashboard-store/dashboard-data-table/dashboard-data-table';
import Button from '../../shared-components/Button/custom-button';
const Dashboard = () => {
  const { getDashboardData, getDashboardSurveyListResponse } = useSelector((state) => state.dashboardDetails);
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const { userLoginDetails } = useSelector((state) => state.login);
  const [filteredList, setFilteredList] = useState([])

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const datePickerRef = useRef(null);

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('Authentication') !== null;
    if (isAuthenticated && userLoginDetails.user_id != undefined) {
      dispatch(fetchDashboardHeadersResponse(userLoginDetails.user_id));
      dispatch(fetchSurveyDetailsInDashboard(`${userLoginDetails.user_id}/1`))

    }
    return () => {
      dispatch(getSurveyDetailsInResponse([]));
    };
  }, [dispatch, userLoginDetails.user_id]);

  useEffect(() => {
    document.addEventListener('keydown', handleEscape, false);
    document.addEventListener("click", hideOnClickOutside, true)

    return () => {
      document.removeEventListener('keydown', handleEscape, false);
    };
  }, []);

  useEffect(() => {
    setFilteredList(getDashboardSurveyListResponse);
  }, [getDashboardSurveyListResponse])

  const handleEscape = (event) => {
    if (event.key === 'Escape') {
      setShowDatePicker(false);
      setDateRange({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      });
    }
  };
  const hideOnClickOutside = (e) => {
    //  console.log(refOne.current,"ref click")
    //  console.log(e.target,'e.target')
    if (datePickerRef.current && !datePickerRef.current.contains(e.target)) {
      setShowDatePicker(false);
      setDateRange({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      });    }
  }

  useEffect(() => {
    if (showDatePicker && datePickerRef.current) {
      const dateRangePickerWrapper = datePickerRef.current.querySelector('.rdrDateRangePickerWrapper');
      if (dateRangePickerWrapper) {
        dateRangePickerWrapper.focus();
      }
    }
  }, [showDatePicker]);

  const handleCalendarClick = () => {
    setShowDatePicker(true);
  };

  const handleDateSelect = (dateRange) => {
    setDateRange(dateRange.selection);
    // console.log([...dateRange, dateRange.selection],"check")
    // setShowDatePicker(false);
  };
  const formatDateString = (dateString) => {
    const [month, day, year] = dateString.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  };
  const filterResponses = () => {
    const startDate = dateRange.startDate.toLocaleDateString();
    const endDate = dateRange.endDate.toLocaleDateString();
    const formattedStartDate = formatDateString(startDate);
    const formattedEndDate = formatDateString(endDate);
    const formattedDateRange = `${formattedStartDate}/${formattedEndDate}`;
    dispatch(fetchSurveyDetailsInDashboardWithCustomDate(`${userLoginDetails.user_id}/${formattedDateRange}`))


    setShowDatePicker(false);

  };
  return (
    
    <div className='col-12 project-survey'>
      <div style={{ 'marginLeft': '22%', 'marginTop': '1%','marginRight':'5%'}}>
          <div className="row mb-3">
            <div className="col-3">
              <div className="card">
                <div className="card-body d-flex justify-content-between align-items-center">
                  <span className="bi bi-ui-checks text-success h1 mr-2"></span>
                  <div className="d-flex flex-column align-items-end">
                    <h2 >{getDashboardData && getDashboardData?.total_survey}</h2>
                    <span style={{
                      fontWeight: 'bold',
                      fontSize: '12px'
                    }}>Total Survey</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="card">
                <div className="card-body d-flex justify-content-between align-items-center">
                  <span className="bi bi bi-bar-chart-fill text-primary h1 mr-2"></span>
                  <div className="d-flex flex-column align-items-end">
                    <h2>{getDashboardData && getDashboardData?.total_response}</h2>
                    <span style={{
                      fontWeight: 'bold',
                      fontSize: '12px'
                    }}>Total Response</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="card">
                <div className="card-body d-flex justify-content-between align-items-center">
                  <span className="bi bi-calendar-week-fill text-primary h1 mr-2"></span>
                  <div className="d-flex flex-column align-items-end">
                    <h2>{getDashboardData && getDashboardData?.week_response}</h2>
                    <span style={{
                      fontWeight: 'bold',
                      fontSize: '12px'
                    }}>This Week Response</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="card">
                <div className="card-body d-flex justify-content-between align-items-center">
                  <span className="bi bi-calendar-event-fill text-primary h1 mr-2"></span>
                  <div className="d-flex flex-column align-items-end">
                    <h2>{getDashboardData && getDashboardData?.today_response}</h2>
                    <span style={{
                      fontWeight: 'bold',
                      fontSize: '12px'
                    }}>Today Response</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
       </div>

       <Card style={{ 'marginLeft': '22%', 'marginTop': '1%','marginRight':'5%'}}>
      <Card.Body>
        <div style={{ width:'20%', float: 'left', marginTop:'5px' }}>
        <h5 >Response Count</h5>
        </div>
                    
       <div className="card text-right all-button" style={{ backgroundColor: '#3990ce', float: 'right', width:'100px', marginLeft:'5px' }} onClick={() => dispatch(fetchSurveyDetailsInDashboard(`${userLoginDetails.user_id}/1`))}>
      <button className="btn" style={{ backgroundColor: 'transparent', border: 'none', color: 'white', height: '100%' }}>
        <div className="d-flex align-items-center justify-content-center">
          <p style={{ margin: '0', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>All</p>
        </div>
      </button>
    </div>
                    
    <div className="card text-center this-month-button" style={{ backgroundColor: '#3990ce', float: 'right', width:'100px', marginLeft:'5px' }} onClick={() => dispatch(fetchSurveyDetailsInDashboard(`${userLoginDetails.user_id}/2`))}>
      <button className="btn" style={{ backgroundColor: 'transparent', border: 'none', color: 'white' }}>
        <div className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
        <p style={{ margin: '0', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Month</p>
        </div>
      </button>
    </div>

  <div className="card text-center this-week-button" style={{ backgroundColor: '#3990ce', float: 'right', width:'100px', marginLeft:'5px' }} onClick={() => dispatch(fetchSurveyDetailsInDashboard(`${userLoginDetails.user_id}/3`))}>
      <button className="btn" style={{ backgroundColor: 'transparent', border: 'none', color: 'white' }}>
        <div className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
          <p  style={{ margin: '0', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Week</p>
        </div>
      </button>
    </div>

    <div className="card text-center yesterday-button" style={{ backgroundColor: '#3990ce', float: 'right', width:'100px', marginLeft:'5px' }} onClick={() => dispatch(fetchSurveyDetailsInDashboard(`${userLoginDetails.user_id}/4`))}>
      <button className="btn" style={{ backgroundColor: 'transparent', border: 'none', color: 'white' }}>
        <div className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
          <p  style={{ margin: '0', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Yesterday</p>
        </div>
      </button>
    </div>

    <div className="card text-center today-button" style={{ backgroundColor: '#3990ce', float: 'right', width:'100px', marginLeft:'5px' }} onClick={() => dispatch(fetchSurveyDetailsInDashboard(`${userLoginDetails.user_id}/5`))}>
      <button className="btn" style={{ backgroundColor: 'transparent', border: 'none', color: 'white' }}>
        <div className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
          <p  style={{ margin: '0', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Today</p>
        </div>
      </button>
    </div>

    <div className="card text-center calendar-button" style={{ backgroundColor: 'black', float: 'right', width:'100px', marginLeft:'5px' }} onClick={handleCalendarClick}>
      <button className="btn" style={{ backgroundColor: 'transparent', border: 'none', color: 'white' }}>
        <div className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
          {/* <i className="pi pi-calendar" style={{ fontSize: '2rem' }}></i> */}
          <p  style={{ margin: '0', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Custom</p>

        </div>
      </button>
    </div>
    
    {showDatePicker && (
      <div className="date-picker-container" ref={datePickerRef} 
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'start', backgroundColor:'sliver' }}>
        <DateRange
          ranges={[dateRange]}
          onChange={handleDateSelect}
          minDate={new Date('2022-01-01')}
        />
        <Button onClick={filterResponses}
         name='yes'
         icon="pi pi-filter-fill"
         className='p-button-sm custom-primary-button font-13'
         />
      </div>
    )}
    </Card.Body>
       </Card>

       <Card style={{ 'marginLeft': '22%', 'marginTop': '1%','marginRight':'5%'}}>
       {filteredList.length > 0 && (
              <>
                {/* <div className="date-range-container">
                  <span>Selected Date Range:</span>
                  <span>{`${dateRange[0].startDate.toDateString()} - ${dateRange[0].endDate.toDateString()}`}</span>
                </div> */}
                <DashboardDataTable data={filteredList} />
              </>
            )}
       </Card>
      
       <br></br><br></br><br></br><br></br><br></br><br></br>
    </div>
  );
};

export default Dashboard;
